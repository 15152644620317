import { UserRoles } from "~/types";

export const getRoleName = (role: UserRoles) => {
  return (
    (
      {
        [UserRoles.OPERATIONAL]: "Operador",
        [UserRoles.SUPPLIER]: "Fornecedor da Telefonica",
      } as Record<UserRoles, string>
    )[role] || undefined
  );
};
